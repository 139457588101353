import { Web3Provider } from "@ethersproject/providers";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { ethers } from "ethers";
import { useCallback, useEffect, useMemo, useState } from "react";
import Web3Modal from "web3modal";

// Enter a valid infura key here to avoid being rate limited
// You can get a key for free at https://infura.io/register
const INFURA_ID = "f3010ae0bc914e08b9925ed4f7abe4de";

const NETWORK = "mainnet";


function useWeb3Modal(config:any = {}) {
  const [provider, setProvider] = useState<any>();
  const [autoLoaded, setAutoLoaded] = useState<boolean>(false);
  const { autoLoad = true, infuraId = INFURA_ID, network = NETWORK } = config;
  let [account, setAccount] = useState('');

  // Web3Modal also supports many other wallets.
  // You can see other options at https://github.com/Web3Modal/web3modal
  const web3Modal = useMemo(() => {
    return new Web3Modal({
      network,
      cacheProvider: true,
      providerOptions: {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            infuraId,
          },
        },
      },
    });
  }, [infuraId, network]);

  // Open wallet selection modal.

  const loadWeb3Modal = useCallback(async () => {
    const newProvider = await web3Modal.connect();
    
    if(newProvider.chainId != '0x1'){
      await newProvider.request({ method: 'wallet_switchEthereumChain', params:[{chainId: "0x1"}]});
    }
    setProvider(new Web3Provider(newProvider));
    newProvider.on("chainChanged", (chainId: string) => {
      if(chainId !== '0x1'){
        logoutOfWeb3Modal();
      }
    });
    
    newProvider.on('accountsChanged', (account:any) => setAccount(account[0]));

  }, [web3Modal]);
  const logoutOfWeb3Modal = useCallback(
    async function () {
      web3Modal.clearCachedProvider();
      window.location.reload();
    },
    [web3Modal],
  );

  useEffect(() => {
    if(provider && account){
      loadWeb3Modal();
    }
  }, [account]);

  async function loadWeb3(){
    await loadWeb3Modal();
  }

  // If autoLoad is enabled and the the wallet had been loaded before, load it automatically now.
  /*useEffect(() => {
    if (autoLoad && !autoLoaded && web3Modal.cachedProvider) {
      loadWeb3Modal();
      setAutoLoaded(true);
    }
  }, [autoLoad, autoLoaded, loadWeb3Modal, setAutoLoaded, web3Modal.cachedProvider]);*/

  return [provider, loadWeb3Modal, logoutOfWeb3Modal];
}

export default useWeb3Modal;
