import { getDefaultProvider } from "@ethersproject/providers";
import { Contract } from "@ethersproject/contracts";
import { ethers, providers } from "ethers";
import { parseEther } from "@ethersproject/units";
import React, { useEffect, useState } from "react";
import useWeb3Modal from "./utils/useWeb3Modal";
import Button from './components/button/button';
import logo from './assets/logo.png';
import roadmap from './assets/roadmap.jpg';
import receive from './assets/receive.jpg';
import img1 from './assets/1.png';
import img2 from './assets/2.png';
import img3 from './assets/3.png';
import img4 from './assets/4.png';
import img5 from './assets/5.png';
import img6 from './assets/6.png';
import discord from './assets/discord.png';
import facebook from './assets/facebook.png';
import insta from './assets/instagram.png';
import twitter from './assets/twitter.png';
import opensea from './assets/opensea.png';
import './App.css';
import ClipLoader from "react-spinners/ClipLoader";
import { promises } from "fs";

const MUHREEN = require('./contracts/muhreens.json');
const contractAddress:string = "0x614bd1970f5c19c0424974a354e0d1c3c1441d84";

const controlButtonStyle = {
  width: '20px',
  backgroundColor: '#ffbc22',
  borderRadius: '5px',
  color: 'white',
  cursor: 'pointer'
}

enum ACTION_STATUS {
  INIT = "INIT",
  LOADING = "LOADING",
  ERROR = "ERROR",
  MINTING = "MINTING",
  SUCCESS = "SUCCESS",
  SALE_INACTIVE = "SALE_INACTIVE",
  NA = "NA"
}

async function getEthBalance(provider:any, address:string) {
  let balance: any;
  try{
    if(address){

      balance = await provider.getBalance(address);
    }

  } catch(err) {

  }
  return ethers.utils.formatEther(balance);
}


function WalletButton(props:any) {
  const [account, setAccount] = useState("");
  const [rendered, setRendered] = useState("");

  useEffect(() => {
    async function fetchAccount() {
      
      try {
        if (!props.provider) {
          return;
        }
        props.setAppStatus(ACTION_STATUS.INIT);
        const accounts = await props.provider.listAccounts();
        //console.log(accounts);
        setAccount(accounts[0]);
        props.accountDetails(accounts[0]);
        setRendered(account.substring(0, 6) + "..." + account.substring(36));
      } catch (err) {
        setAccount("");
        setRendered("");
        //console.log(err);
    
      }
    }
    fetchAccount();
  }, [account, props.provider, setAccount, setRendered]);
  

  return (
    props.disconnect == false ? <Button 
      width="150px"
      minHeight="50px"
      onClick={() => {
        if (!props.provider) {
          props.loadWeb3Modal();
        } else {
          props.logoutOfWeb3Modal();
        }
      }}
    >
      {rendered === "" && "Connect Wallet"}
      {rendered !== "" && rendered}
    </Button> : 
    <Button minHeight='20px' width='250px' onClick={() => props.logoutOfWeb3Modal()}>
      Disconnect {rendered !== "" && rendered}
    </Button>
  );
}


function App() {
  const [provider, loadWeb3Modal, logoutOfWeb3Modal] = useWeb3Modal();
  let [purchaseAmount, setPurchaseAmount] = useState<number>(0);
  let [totalCostAmount, setTotalCostAmount] = useState<string>('0');
  let [accountAddress, setAccountAddress] = useState<string>("");
  let [accountBalance, setAccountBalance] = useState<string>("0");
  let [mintReady, setMintReady] = useState<number>(1);
  let [contract, setContract] = useState<any>();
  const [appStatus, setAppStatus] = useState<ACTION_STATUS>(ACTION_STATUS.NA)
  const [appStatusMessage, setAppStatusMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [receiptHash, setReceiptHash] = useState<string>('');
  const [nftCounter, setNftCounter] = useState<number>(0);


  React.useEffect(() => {
    mintNFT(accountBalance);
    if(purchaseAmount == 0){
      setTotalCostAmount("0");
    } else {
      
    }
  }, [purchaseAmount]);

  React.useEffect(() => {
    if(provider && accountAddress){
      getEthBalance(provider, accountAddress).then(x => {
        setAccountBalance(x);
        connectToContract(provider);
      });
    }
  }, [provider, accountAddress]);

  React.useEffect(() => {
    if(contract){
      checkIfSaleIsActive();
      getTotalSupply();
    }
  }, [contract])

  async function connectToContract(val:any){
    if(val.provider){
      let pr = new ethers.providers.Web3Provider(val.provider);
      let signer = pr.getSigner();
      setContract(new Contract(contractAddress, MUHREEN.abi, signer));
    }
  }

  async function accountDetails(x:any){
    setAccountAddress(x);
  }
  async function decreaseAmount(){
    if(purchaseAmount > 0){
      setLoading(true);
      let curr = purchaseAmount - 1;
      setPurchaseAmount(curr);
      //fetchTotalCost(curr);
    }
  }
  async function increaseAmount(){
    if(purchaseAmount >= 0 && purchaseAmount < 3){
      setLoading(true);
      let curr = purchaseAmount + 1;
      setPurchaseAmount(curr);
      //fetchTotalCost(curr);
    }
  }
  async function fetchTotalCost(tokensToPurchase:number){
    setLoading(true);
    try{
      let totalCost = await contract.calculateForwardValue(tokensToPurchase.toString());

      setTotalCostAmount(ethers.utils.formatEther(totalCost.toString()));
    } catch(error) {
    }
      setTimeout(() => setLoading(false), 3000);
  }

  async function mintNFT(bal:any){
    if((bal) > totalCostAmount && purchaseAmount > 0){
      setMintReady(0);
    } else {
      setMintReady(1);
    }
  }

  async function mint(){
    setAppStatus(ACTION_STATUS.MINTING);
    setAppStatusMessage('Minting . . .')
    
    try{
      let totalCost = await contract.calculateForwardValue(purchaseAmount.toString());
      let mintTXN = await contract.mintToken(purchaseAmount, {value: totalCost });
      let receipt = await mintTXN.wait();
      setReceiptHash(receipt.transactionHash);
      setAppStatusMessage('');
      setAppStatus(ACTION_STATUS.SUCCESS);
    } catch(error) {
      setAppStatus(ACTION_STATUS.ERROR);
    }
  }

  async function mintReset(){
    setReceiptHash('')
    setPurchaseAmount(0);
    setAppStatus(ACTION_STATUS.NA);
  }

  async function checkIfSaleIsActive(){
    try{
      if(await contract.saleIsActive()){
        setTimeout(() => setAppStatus(ACTION_STATUS.NA), 3000);
      } else {
        setTimeout(() => setAppStatus(ACTION_STATUS.SALE_INACTIVE), 3000);
      }
    } catch (error) {
      //console.log(error);
    }
  }

  async function getTotalSupply(){
    try{
      let count = await contract.totalSupply();
      if(count > 0){
        setNftCounter(parseInt(count.toString()));
      } else {
        setNftCounter(0);
      }
    } catch (error) {
    }
  }



  useEffect(() => {
    console.log('run');
    async function getTotalSupplyOpensea(){
      const options = {method: 'GET', headers: {Accept: 'application/json'}};
      fetch('https://api.opensea.io/api/v1/collection/muhreens/stats', options)
        .then(response => response.json())
        .then(response => setNftCounter(response.stats.total_supply))
        .catch(err => console.error(err));
    }
  
    getTotalSupplyOpensea();
  }, []);

  useEffect(() => {
    if(accountAddress && appStatus === 'NA'){
      fetchTotalCost(purchaseAmount);
    }
  }, [purchaseAmount])

  useEffect(() => {
    if(appStatus === 'NA'){
      setLoading(false); 
    }
    if(appStatus === 'SUCCESS'){
      getTotalSupply();
    }
  },[appStatus]);

  return (
    <div className="App">
      <div className='mainContentContainer'>
        { /*<div className={`block ${provider ? 'blockOn' : 'blockOff'}`}>
        </div> */}
        <div className='header'>
          <div className='imgContainer'>
            <img src={logo} />
          </div>
          
          <div className='socialContainer'>
            <ul>
              <a href='https://discord.gg/CUAMXM5QZw' target="_blank"><li><img src={discord} /></li></a>
              <a href='https://www.facebook.com/muhreens' target="_blank"><li><img src={facebook} /></li></a>
              <a href='https://twitter.com/muhreens' target="_blank"><li><img src={twitter} /></li></a>
              <a href='https://opensea.io/collection/muhreens' target="_blank"><li><img src={opensea} /></li></a>
            </ul>
          </div>
        </div>
        <div className='cover'>
          <div className='coverImage'></div>
        </div>
        <div className='description'>
          <div className='main'>
            <h2 className='first noShow'>MUHREENS ARE <span>9999</span> UNIQUE NFT'S FROM</h2> 
            <h2 className='first show'> MUHREENS ARE </h2>
            <h2 className='first show'>  <span>9999</span> </h2>
            <h2 className='first show'> UNIQUE NFT'S FROM </h2>
            <h2 className='second'>OVER 50 HAND-DRAWN PIECES FROM THE CREATOR OF</h2>
            <h2 className='third'>TERMINAL LANCE!</h2>
          </div>
        </div>
        <div className='mainContainer'>
          <div className='cardLeft'>
            <ul>
              <li style={{transform:'rotate(0.02turn)'}}><img src={img1} /></li>
              <li style={{transform:'rotate(-0.03turn)'}}><img src={img2} /></li>
              <li style={{transform:'rotate(0.024turn)'}}><img src={img3} /></li>
            </ul>
          </div>
          <div className='cardRight'>
            <ul>
              <li style={{transform:'rotate(-0.02turn)'}}><img src={img4} /></li>
              <li style={{transform:'rotate(0.03turn)'}}><img src={img5} /></li>
              <li style={{transform:'rotate(-0.024turn)'}}><img src={img6} /></li>
            </ul>
          </div>
          <div className='minter'>

            <div className='sub'>
              <h3>ENLIST YOUR <span>MUHREEN</span> TODAY!</h3>
            </div>
            <div className='minterContainer'>
              <ul>
                <li>
                  <label>ETH Balance: </label>
                  <span>{accountBalance ? accountBalance.substring(0, 6) : "0"} ETH </span>
                </li>
                <hr/>
                <li>
                  <label>Cost Per Grunt: </label>
                  <span>0.01 ETH </span>
                </li>
                <li className='amountControl'>
                  <label>Purchase Quantity: </label>
                  <button disabled={loading} style={controlButtonStyle} onClick={() => decreaseAmount()}>-</button>
                  <span>{purchaseAmount}</span>
                  <button disabled={loading} style={controlButtonStyle} onClick={() => increaseAmount()}>+</button>
                  <Button disabled={loading} width='50px' onClick={() => setPurchaseAmount(3)}>max</Button>
                </li>
                <li>
                  <div>
                    <label>Total ETH Cost: </label>
                    <span>(Gas Cost Not Included) </span>
                  </div>
                  {loading ? <span>calculating . . .</span> : <span>{totalCostAmount} ETH</span>}
                </li>
              </ul>
              <div className='mintButton'>
                <Button 
                  disabled={mintReady || loading}
                  width='150px'
                  onClick={() => mint()}
                >
                  ENLIST!</Button>
              </div>
              <div className={`disconnectButton ${accountAddress ? 'disconnected' : 'connected'}`}>
                <WalletButton disconnect={true} provider={provider} loadWeb3Modal={loadWeb3Modal} logoutOfWeb3Modal={logoutOfWeb3Modal} accountDetails={accountDetails} setAppStatus={setAppStatus}/>
              </div>
              <div className={`connectModal ${accountAddress ? 'connected' : 'disconnected'}`}>
                
                <div className=''>
                    <WalletButton disconnect={false} provider={provider} loadWeb3Modal={loadWeb3Modal} logoutOfWeb3Modal={logoutOfWeb3Modal} accountDetails={accountDetails} setAppStatus={setAppStatus}/>
                </div>
              </div>
              <div className='loading-container' style={{
                display: appStatus == ACTION_STATUS.INIT || appStatus == ACTION_STATUS.MINTING  ? 'flex' : 'none'
              }}>
                <div>
                  <ClipLoader color={'#ffbc22'} loading={appStatus == ACTION_STATUS.INIT || appStatus == ACTION_STATUS.MINTING} size={150} />
                </div>
                <h3>{ appStatus == ACTION_STATUS.INIT ? "Loading . . ."  : appStatusMessage} </h3>
              </div>
              <div className='success-container' style={{
                display: appStatus == ACTION_STATUS.SUCCESS ? 'flex' : 'none'
              }}>
                {purchaseAmount > 1 ? <h2> MINT COMPLETE. MUHREENS SUCCESSFULLY DEPLOYED!</h2> : <h2> MINT COMPLETE. MUHREEN SUCCESSFULLY DEPLOYED!</h2>}
                <span>View Transaction:</span>
                <div className='view-txn'>
                  <a href={`https://opensea.io/assets/muhreens`} target="_blank">
                    <span><img src={'https://storage.googleapis.com/opensea-static/Logomark/Logomark-Blue.png'} /></span>
                    <label>OpenSea</label>
                  </a>
                  <a href={`https://etherscan.io/tx/${receiptHash}`} target="_blank">
                    <span><img src={'https://etherscan.io/images/brandassets/etherscan-logo-circle.png'} /></span>
                    <label>Etherscan</label>
                  </a>
                </div>
                <div className='reset-button'><Button width='150px' onClick={() => mintReset()}>ENLIST ANOTHER</Button></div>
                
              </div>
              <div className={`soon`} style={{
                display: appStatus == ACTION_STATUS.SALE_INACTIVE ? 'flex' : 'none'
              }}>
                <h1>SALE COMING SOON!</h1>
              </div>
              <div className={`mint-error`} style={{
                display: appStatus == ACTION_STATUS.ERROR ? 'flex' : 'none'
              }}>
                <h1>ERROR, PLEASE TRY AGAIN!</h1>
                <Button width='150px' onClick={() => mintReset()}>TRY AGAIN!</Button>
              </div>
            </div>
            { nftCounter > 0 ? <div className='counter fadeInAnimation'>
              <h4>{nftCounter}</h4> 
              <p>MUHREENS ENLISTED</p>
            </div> : <div className='counter '></div> }
            <div className='sub'>
              <div><span>FIRST</span> <span>3333 = 0.01 ETH</span></div>
              <div><span>SECOND</span> <span>3333 = 0.02 ETH</span></div>
              <div><span>THIRD</span> <span>3333 = 0.03 ETH</span></div>
            </div>
          </div>
        </div>
        <div className='aboutProject'>
              <div className='project'>
                <h1>
                  THE PROJECT
                </h1>
                <h2>
                  Owning a MUHREEN NFT is your ticket to starring in an ANIMATED SHORT FILM!
                </h2>
                <div>
                  <p>
                    At Post Animation, we like to make animated short films about MUHREENS. In early 2021, we released our first animated short film in the POST series called “FRAG OUT,” written and directed by Terminal Lance creator Maximilian Uriarte.
                  </p>
                </div>
                <div className='video'>
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/SHbSpmRVAJA" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
                </div>
                <div>
                  <p>
                    We want to make more animated short films, and we thought NFT’s would be a fun way to raise money, involve the audience, and create a community. All of the funds raised from our MUHREEN NFT sale will go into creating more animated short films! But wait! There’s more!
                  </p>
                </div>
                <div>
                  <p>For every film we make in the POST series, we will be randomly selecting 2 of our 9999 MUHREEN NFT’s to STAR in the animated short! We will 3D model and craft whichever MUHREENS are selected and they will star in a 3-5 minute long short film written and directed by series creator Maximilian Uriarte. Each film will be driven by our NFT holders, with MUHREENS and even story ideas contributed from our community.</p>
                </div>
              </div>
              <div className='roadmapimage'>
                <img src={roadmap} />
              </div>
              <div className='holdersgetimage'>
                <img src={receive} />
              </div>
              <div className='team'>
                <h1>
                  THE TEAM
                </h1>
                <div className='members'>
                  <ul className='members-container'>
                    <li className='element'>
                        <img src={'https://pbs.twimg.com/profile_images/1149841157221826560/ZnFY0zle_400x400.jpg'} />
                        <h4>Maximilian Uriarte</h4>
                        <label>Creator, Writer, Director and Artist</label>
                        <a href='https://twitter.com/TLCplMax' target="_blank">@TLCplMax</a>
                    </li>
                    <li className='element'>
                        <img src={'https://pbs.twimg.com/profile_images/1453095648622907393/71cOEU1n_400x400.jpg'} />
                        <h4>Mike Dowling</h4>
                        <label>Producer</label>
                        <a href='https://twitter.com/MikeD0WLING' target="_blank">@MikeD0WLING</a>

                    </li>
                    <li className='element'>
                        <img src={'https://pbs.twimg.com/profile_images/1191498969714712577/7LWBGnC7_400x400.jpg'} />
                        <h4>Christian Martinez</h4>
                        <label>Developer</label>
                        <a href='https://twitter.com/ChristianBMz' target="_blank">@ChristianBMz</a>
                    </li>
                  </ul>
                </div>
              </div>
        </div>
        <div className='footer'></div>
      </div>
    </div>
  );
}

export default App;
