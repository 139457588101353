import React from "react";
import './style.css';

const buttonStyle = {
    border: "none",
    borderRadius: "10px",
    height: "50px",
    width: "200px",
    fontSize: "1.2em",
    fontWeight: 700,
    color: "blue",
}

export default function Button(props: any){
    let [hover, setHover] = React.useState(0);
    return(
        <button 
        disabled={props.disabled}
        className='buttonMain'
        style={{
            width: props.width ? props.width : '100px',
            minHeight: props.minHeight ? props.minHeight : '40px',
            maxHeight: props.maxHeight ? props.maxHeight : '50px',
            fontSize: props.fontSize ? props.fontSize : '100%',
            backgroundColor: props.disabled ? '#dcbca4': props.colorReverse ? !hover ? 'white' : '#ffbc22' : !hover ? '#ffbc22' : 'white',
            color: props.disabled ? 'white': props.colorReverse ? !hover ? 'white' : '#ffbc22' : !hover ? 'white' : '#ffbc22',
            border: 'solid 1px'
        }}
        onMouseEnter={() => setHover(1)}
        onMouseLeave={() => setHover(0)}
        onClick={() => props.onClick()}>{props.children}</button>
    );
}